export default {
    graphqlServer: 'appotime.motive.lv:8082',
    recaptcha: {
        key: '6LdbgCYpAAAAAPzxcO1Tv9TBaK8iPDWqzilBHvTw',
    },
    stripe: {
        public:
            'pk_test_51OHmqTClrAD238487j892FQ6eH1w2aJUjLmJOiAmrdr8VlhjUsRBqcZkiTx4pdqgYqizNypgkaQd71uB2lE3NZg600sF36EV4z',
    },
};

/**
 * @flow
 */

import React from 'react';
import styled from 'styled-components';
import { Button, Input } from 'semantic-ui-react';
import withModal from '../../../../lib/withModal';
import { Select as BaseSelect } from '../../../../components';
import CreateClientDialog from '../../../clients/containers/CreateClientDialog';
import ClientDetails from '../../../clients/components/details/ClientDetails';
import BlockedClientDialog from '../../../clients/components/BlockedClientDialog';
import { find } from 'lodash';
// import { Button } from '../../components';

import type { Client } from '../../../../type';
import { defineMessages } from 'react-intl';

type ClientSelectProps = {
    onAdd: Function,
    onSearch: Function,
    onSetClient: Function,
    size: number,
    props: any,
    clients: Array<Client>,
    onClientUpdate: Function,
};

const Select = styled(BaseSelect)`
    flex: 1 1 auto;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-right: none !important;
    &::after {
        content: attr(data-phone);
        text-align: right;
        display: inline-block;
        position: absolute;
        right: 35px;
        color: rgba(0, 0, 0, 0.4);
    }
    .item::after {
        content: attr(data-notes);
        display: block;
        color: rgba(0, 0, 0, 0.4);
        margin-top: 10px;
    }
    & > input {
        width: auto !important;
    }
`;

const AddButton = styled(Button)`
    box-shadow: none !important;
    border: 1px solid rgba(34, 36, 38, 0.15) !important;
    border-right-width: 0 !important;
`;

const WalkinClientButton = styled(Button)`
    box-shadow: none !important;
    border: 1px solid rgba(34, 36, 38, 0.15) !important;
`;

const InfoButtonComponent = styled(Button)`
    &&.disabled {
        box-shadow: 0 0 0 1px rgba(34, 36, 38, 0.15) inset !important;
    }
`;

// The button opens modal window with form that creates a new client
const AddButtonTrigger = withModal(({ size, open, onOpen, onClose, onAdd }) => (
    <CreateClientDialog
        trigger={
            <AddButton
                onClick={e => {
                    e.preventDefault();
                    onOpen();
                }}
                icon="add"
                size={size}
                basic
            />
        }
        open={open}
        onClose={onClose}
        onAdd={onAdd}
    />
));

const InfoButtonTrigger = withModal(
    ({ size, open, onOpen, onClose, client, onClientUpdate }) => (
        <ClientDetails
            isModalOpen={open}
            client={client}
            onModalClose={onClose}
            onClientUpdate={onClientUpdate}
            trigger={
                <InfoButtonComponent
                    onClick={e => {
                        e.preventDefault();
                        onOpen();
                    }}
                    icon="info"
                    size={size}
                    basic
                    disabled={!client || !client.id}
                />
            }
        />
    ),
);

const ClientSelect = ({
    onAdd,
    onSearch,
    onSetClient,
    size,
    clients,
    search,
    onClientUpdate,
    newAppointmentUseCase,
    clientState,
    setClient,
    open,
    onCloseDialog,
    setOpen,
    intl,
    ...props
}: ClientSelectProps) => {
    const selectedClient = find(clients, ['id', props.value]) || {};
    if (clientState.id != selectedClient.id) {
        setClient(selectedClient);
        if (selectedClient.blockedAt) {
            setOpen(true);
        }
    }
    return (
        <Input action>
            <Select
                onSearchChange={onSearch}
                size={size}
                fluid
                data-phone={selectedClient.phone}
                search={() => props.options}
                {...props}
            />
            <AddButtonTrigger size={size} onAdd={onAdd} />
            <InfoButtonTrigger
                size={size}
                client={selectedClient}
                onClientUpdate={onClientUpdate}
            />
            <BlockedClientDialog
                client={selectedClient}
                open={open}
                onCloseDialog={onCloseDialog}
                onClose={() => {
                    setOpen(false);
                }}
            />
        </Input>
    );
};

export default ClientSelect;

export default {
    sentry: {
        url: 'https://c47876bad02b4a2b96f4ea257ac640d8@sentry.io/1222134',
        sampleRate: 1, // send 100% of events
    },
    recaptcha: {
        key: '6LcgbSElAAAAAJeCsbm3Vn0kwUqnbVu4Pu33YRrq',
    },
    tawk: {
        enabled: false,
        url: 'https://embed.tawk.to/5b4edeb8df040c3e9e0bacb3/default',
    },
}

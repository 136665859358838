/**
 *  @flow
 */

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';
import moment from 'moment';
import { reduxForm, SubmissionError, getFormValues, reset } from 'redux-form';
import {
    compose,
    withProps,
    withHandlers,
    withState,
    lifecycle,
} from 'recompose';
import { pick, isEqual, keys, get } from 'lodash';
import uuid from 'uuid';

import CreateClientDialog from '../components/CreateClientDialog';
import withUserLookup from '../../../lib/withUserLookup';
import validate from './form/clientFormValidate';
import { injectIntl, defineMessages } from 'react-intl';
import { GET_CLIENTS_QUERY } from './ClientsList';
import { CLIENT_SUMMARY_QUERY } from './ClientsPage';
// import { GET_ALL_CLIENTS_QUERY } from '../../../pages/appointments/containers/form/ClientSelect';

const intlMessages = defineMessages({
    phoneExistingsError: {
        id: 'pages.clients.form.ClientForm.phoneExistingsError',
        defaultMessage: 'Client with the same phone number already exists',
    },
    emailExistingsError: {
        id: 'pages.clients.form.ClientForm.emailExistingsError',
        defaultMessage: 'Client with the same email already exists',
    },
    emailPhoneError: {
        id: 'pages.clients.form.ClientForm.emailPhoneError',
        defaultMessage:
            'There are two different users with the provided phone and email',
    },
    defaultErrorMessage: {
        id: 'errors.defaultMessage',
        defaultMessage: 'Something went wrong',
    },
});

const withForm = reduxForm({
    form: 'createClient',
    touchOnBlur: false,
    enableReinitialize: true,
    validate,
});

// GraphQL query to create a new client
const CREATE_CLIENT_QUERY = gql`
    mutation createClient($input: CreateClientInput!) {
        createClient(input: $input) {
            client {
                id
                email
                firstName
                lastName
                phone
                notes
                birthday
                gender
                type
                blockedAt
                blockedBy {
                    id
                    firstName
                    lastName
                }
            }
        }
    }
`;

const updateSummary = (store, salon, gender) => {
    const data = store.readQuery({
        query: CLIENT_SUMMARY_QUERY,
        variables: {
            salon,
        },
    });

    const summary = get(data, 'viewer.salon.clients.summary');

    summary.total++;
    if (gender === 'MALE') summary.males++;
    else if (gender === 'FEMALE') summary.females++;

    store.writeQuery({
        query: CLIENT_SUMMARY_QUERY,
        variables: {
            salon,
        },
        data,
    });
};

const withData = graphql(CREATE_CLIENT_QUERY, {
    props: ({ mutate, ownProps }) => ({
        // Handle form submission and create a new client
        onSubmit: formData => {
            const birthday = formData.birthday
                ? moment(formData.birthday).format('MM-DD-YYYY')
                : '';
            const phone = (formData.phone || '').replace(/[^+\d]/g, '');
            const blockedAt = formData.blocked
                ? moment().format('YYYY-MM-DD HH:mm')
                : null;
            const blockedBy = formData.blocked ? ownProps.userId : null;
            const { blocked, ...rest } = formData;
            const mutation = mutate({
                variables: {
                    input: {
                        salon: ownProps.salon,
                        ...rest,
                        phone,
                        birthday,
                        blockedAt,
                        blockedBy,
                    },
                },
                // Implement optimistinc response to compensate network latency
                // and add a new client directly to the cache
                optimisticResponse: {
                    __typename: 'Mutation',
                    createClient: {
                        __typename: 'CreateClientPayload',
                        client: {
                            __typename: 'Client',
                            ...formData,
                            phone,
                            id: uuid.v4(),
                            salon: ownProps.salon,
                            notes: formData.notes || '',
                            birthday: formData.birthday || '',
                            type: 'LOCAL',
                            blockedAt,
                            blockedBy,
                        },
                    },
                },
                // Update local store with a new client
                update: (store, { data: { createClient } }) => {
                    ///HACK: GET_ALL_CLIENTS_QUERY used for the workaround of issue: https://github.com/apollographql/apollo-client/issues/1701
                                       
                    const data = store.readQuery({
                        query: GET_CLIENTS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                            search: ownProps.searchQuery || undefined,
                            first: 20,
                        },
                    });

                    var newClient = {
                        node: createClient.client,
                        __typename: 'ClientEdge',
                        cursor: btoa(createClient.client.id),
                    };
                    //Adding new client to the start of array, because of paging.
                    data.viewer.salon.clients.edges.unshift(newClient);

                    store.writeQuery({
                        query: GET_CLIENTS_QUERY,
                        variables: {
                            salon: ownProps.salon,
                            search: ownProps.searchQuery || undefined,
                            first: 20,
                        },
                        data,
                    });

                    updateSummary(
                        store,
                        ownProps.salon,
                        createClient.client.gender,
                    );
                },
            });

            const { intl } = ownProps;

            return mutation
                .then(({ data: { createClient } }) => {
                    // Trigger onAdd callback if it's provided
                    if (ownProps.onAdd) {
                        ownProps.onAdd(createClient.client);
                    }

                    // Close dialog after client was created
                    ownProps.onModalClose();
                })
                .catch(error => {
                    const graphQLError =
                        error.graphQLErrors && error.graphQLErrors[0];
                    if (graphQLError) {
                        if (graphQLError.name === 'AlreadyExists') {
                            if (
                                graphQLError.data.error ===
                                'PHONE_ALREADY_EXISTS'
                            ) {
                                throw new SubmissionError({
                                    phone: intl.formatMessage(
                                        intlMessages.phoneExistingsError,
                                    ),
                                });
                            } else if (
                                graphQLError.data.error ===
                                'EMAIL_ALREADY_EXISTS'
                            ) {
                                throw new SubmissionError({
                                    email: intl.formatMessage(
                                        intlMessages.emailExistingsError,
                                    ),
                                });
                            }
                        } else if (graphQLError.name === 'Forbidden') {
                            if (
                                graphQLError.data.error ===
                                'THERE_ARE_TWO_DIFFERENT_USER_WITH_PROVIDED_PHONE_AND_EMAIL'
                            ) {
                                throw new SubmissionError({
                                    _error: intl.formatMessage(
                                        intlMessages.emailPhoneError,
                                    ),
                                });
                            }
                        }

                        throw new SubmissionError({
                            _error: graphQLError.message,
                        });
                    }

                    throw new SubmissionError({
                        _error: intl.formatMessage(
                            intlMessages.defaultErrorMessage,
                        ),
                    });
                });
        },
    }),
});

const props = withProps(({ userLookupStatus }) => ({
    // Make form as read-only if user exists
    readOnly: userLookupStatus === 'EXISTS',
}));

const withModalHandlers = withHandlers({
    onModalClose: ({
        onClose,
        resetUserLookup,
        setInitialValues,
        reset,
    }) => () => {
        resetUserLookup();
        setInitialValues({});
        reset('createClient');
        onClose();
    },
    checkFormPristine: ({ formValues }) => () =>
        isEqual(
            formValues,
            pick(
                {
                    firstName: '',
                    lastName: '',
                    email: '',
                    phone: '+371',
                    gender: '',
                },
                keys(formValues),
            ),
        ),
    handleClear: ({ resetUserLookup, setInitialValues }) => () => {
        resetUserLookup();
        setInitialValues({});
    },
});

const withInitialValues = withState('initialValues', 'setInitialValues', {});

const componentWillReceiveProps = ({
    initialValues,
    setInitialValues,
    open,
    userLookupUser,
    formValues,
}) => {
    if (open) {
        if (userLookupUser) {
            const result = pick(userLookupUser, [
                'email',
                'firstName',
                'lastName',
                'phone',
                'gender',
            ]);
            if (!isEqual(initialValues, result)) {
                setInitialValues(result);
            }
        }
    }
};

export default compose(
    connect(
        ({ user, ...state }) => ({
            salon: user.get('salon'),
            formValues: getFormValues('createClient')(state),
        }),
        { reset },
    ),
    injectIntl,
    withInitialValues,
    withUserLookup,
    withModalHandlers,
    withData,
    props,
    withForm,
    lifecycle({
        componentWillReceiveProps,
    }),
)(CreateClientDialog);

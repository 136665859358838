/**
 * @flow
 */

import React from 'react';
import { Field } from 'redux-form';
import styled from 'styled-components';
import Reaptcha from 'reaptcha';
import { Modal, Form, Input, Message, Icon } from 'semantic-ui-react';
import { FormattedMessage } from 'react-intl';
import { mapProps } from 'recompose';
import { FormField, FormLabel, Button, PhoneInput } from '../../../components';

import type { ReduxForm } from '../../../type';
import config from '../../../config';

type SignupDialogProps = {
    trigger: Function,
    open: boolean,
    onClose: Function,
} & ReduxForm;

const fields = {
    input: FormField(mapProps(({ meta, ...props }) => ({ ...props }))(Input)),
    phone: FormField(PhoneInput),
};

const SignupDialog = styled(Modal)`
    width: 500px !important;
    margin-top: 0 !important;
`;

const ModalActions = styled(Modal.Actions)`
    padding-left: 1.5em !important;
    padding-right: 1.5em !important;
`;

const SubmitButton = styled(Button)`
    margin: 0px !important;
    padding-top: 1em !important;
    padding-bottom: 1em !important;
    text-transform: uppercase !important;
`;

const SignupForm = ({
    trigger,
    open,
    onClose,
    handleSubmit,
    pristine,
    submitting,
    token,
    setToken,
    error,
}: SignupDialogProps) => (
    <SignupDialog trigger={trigger} open={open} onClose={onClose}>
        <Modal.Content>
            {error && (
                <Message error>
                    <Icon name="warning sign" />
                    {error}
                </Message>
            )}
            <Form onSubmit={handleSubmit}>
                <Input name="token" value={token} type="hidden" />
                <Field
                    component={fields.input}
                    name="salonName"
                    label={
                        <FormLabel>
                            <FormattedMessage
                                id="pages.signup.form.salonName"
                                defaultMessage="Salon name"
                            />
                        </FormLabel>
                    }
                    type="text"
                    size="big"
                    simpleValue
                />
                <Field
                    component={fields.input}
                    name="salonAddress"
                    label={
                        <FormLabel>
                            <FormattedMessage
                                id="pages.signup.form.salonAddress"
                                defaultMessage="Salon address"
                            />
                        </FormLabel>
                    }
                    type="text"
                    size="big"
                    simpleValue
                />
                <Field
                    component={fields.input}
                    name="firstName"
                    label={
                        <FormLabel>
                            <FormattedMessage
                                id="pages.signup.form.firstName"
                                defaultMessage="Your first name"
                            />
                        </FormLabel>
                    }
                    type="text"
                    size="big"
                    simpleValue
                />
                <Field
                    component={fields.input}
                    name="lastName"
                    label={
                        <FormLabel>
                            <FormattedMessage
                                id="pages.signup.form.lastName"
                                defaultMessage="Your last name"
                            />
                        </FormLabel>
                    }
                    type="text"
                    size="big"
                    simpleValue
                />
                <Field
                    component={fields.input}
                    name="email"
                    label={
                        <FormLabel>
                            <FormattedMessage
                                id="pages.signup.form.email"
                                defaultMessage="Your e-mail address"
                            />
                        </FormLabel>
                    }
                    type="text"
                    size="big"
                    simpleValue
                />
                <Field
                    component={fields.phone}
                    name="phone"
                    label={
                        <FormLabel>
                            <FormattedMessage
                                id="pages.signup.form.phone"
                                defaultMessage="Your phone number"
                            />
                        </FormLabel>
                    }
                    type="text"
                    size="big"
                    simpleValue
                />
                <Field
                    component={fields.input}
                    name="password"
                    label={
                        <FormLabel>
                            <FormattedMessage
                                id="pages.signup.form.password"
                                defaultMessage="Password"
                            />
                        </FormLabel>
                    }
                    type="password"
                    size="big"
                    simpleValue
                />
                <Field
                    component={fields.input}
                    name="promoCode"
                    label={
                        <FormLabel>
                            <FormattedMessage
                                id="pages.signup.form.promoCode"
                                defaultMessage="Promo code (optional)"
                            />
                        </FormLabel>
                    }
                    type="text"
                    size="big"
                    simpleValue
                />
                <div className="dimmer">
                    <Reaptcha
                        sitekey={config.recaptcha.key}
                        onVerify={t => {
                            setToken(t);
                        }}
                    />
                </div>
            </Form>
        </Modal.Content>
        <ModalActions>
            <SubmitButton
                onClick={handleSubmit}
                disabled={submitting || pristine || !token}
                loading={submitting}
                primary
                fluid
            >
                <FormattedMessage
                    id="pages.signup.button.create"
                    defaultMessage="Create account"
                />
            </SubmitButton>
        </ModalActions>
    </SignupDialog>
);

export default SignupForm;

/**
 * @flow
 */

import React from 'react';
import { Modal, Avatar } from '../../../../components';

import PersonalInformation from '../../containers/details/PersonalInformation';

import type { ModalDialog, Administrator } from '../../../../type';

type AdministratorDetailsProps = {
    administrator: Administrator,
} & ModalDialog;

const AdministratorDetails = ({
    administrator,
    trigger,
    onClose,
    open,
}: AdministratorDetailsProps) => (
    <Modal onClose={onClose} open={open} trigger={trigger}>
        <Modal.Header>
            <Avatar
                name={`${administrator.firstName} ${administrator.lastName}`}
                size={24}
                spaced="right"
                round
            />
            {administrator.firstName} {administrator.lastName}
        </Modal.Header>
        <PersonalInformation
            administrator={administrator}
            readOnly={!administrator.roles.includes('ADMIN')}
            onClose={onClose}
        />
    </Modal>
);

export default AdministratorDetails;
